body {
  margin: 0;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
    to right,
    #f8f6f6 30%,
    rgb(255, 255, 255) 100%
  );
}
.mr-2 {
  margin-right: 5px;
}
a {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}
a.button-link {
  color: #fff;
  text-decoration: none;
  margin-right: 20px;
}
a.button-link:hover {
  color: #fff;
  opacity: 0.8;
}
.App {
  text-align: center;
  padding: 0;
}
.App.content-main {
  margin-top: 7px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.btn {
  margin: 2px;
}
.divbtn {
  float: left;
}
a.menu-link {
  text-decoration: none;
  color: rgb(39, 39, 39) !important;
  padding: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-container-dashboard {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
div.not-found {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
}
.error-login {
  color: red;
  text-align: center;
  margin: auto;
}

.search-icon {
  display: block !important;
  border-radius: inherit !important;
}
.search-icon img {
  width: 24px;
}
.content-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.user-name {
  float: right;
}
.alert-empry-mesaje {
  width: 100%;
  padding: 10px;
  background-color: #c9ac5f;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  border-radius: 6px;
}
.notification-bar-timer {
  height: 25px;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #6ccde7;
  z-index: 20;
}
.notification-bar-timer p {
  margin: 0;
  padding: 0;
}
.avatar-email {
  opacity: 0.8;
}
.notification-expired-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 95vh;

  padding: 20px;
  overflow: hidden;
}
.notification-expired-content div {
  max-width: 1050px;
  color: #000;
}
.notification-expired-content .spacing {
  margin-bottom: 30px;
}
.notification-expired-content h3 {
  color: #333;
  font-size: 21px;
  line-height: 30px;
}
.notification-expired-content h3 a {
  color: #72be44;
}
.notification-expired-content p {
  margin-top: 60px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

table tr td img {
  width: 60px;
}

table tr td {
  vertical-align: middle;
  text-align: left;
  height: 20px;
  position: relative;
  border-bottom: 1px solid #333;
  padding: 1px 5px !important;
}
table tr {
  border: 1px solid #b8b8b8;
}
table tr td.product-name {
  text-transform: capitalize;
}
table tr td:first-child {
  width: 200px;
}
.qr-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 50%; */
  /* border: 5px solid #1e1e1e; */
  margin: auto;
}
.action-column {
  width: 148px;
}
.action-column a {
  align-items: center;
  display: flex;
  float: right;
  height: 30px;
  padding: 4px 6px;
  vertical-align: middle;
  width: 30px;
}

img.iso-logo-image {
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
  width: 50px;
}
.color-link {
  color: #72be44;
}

.color-link:hover {
  text-decoration: none;
  opacity: 0.9;
}

.rocket-icon {
  font-size: 35px;
}

.right-topbar-side {
  display: flex;
  align-items: center;
  gap: 8px;
}
.avatar-email {
  margin-right: 10px;
}
.mobile-hidde,
.avatar-email {
  display: inline-block;
}
.side-drawer {
  display: block;
}
@media (max-width: 768px) {
  .mobile-hidde,
  .avatar-email {
    display: none;
  }
  body {
    background-image: none;
  }
  .side-drawer {
    display: none;
  }
}
.version-panel {
  margin-top: 25px;
}
.copy-right {
  margin-top: 50px !important;
  opacity: 0.8;
}
.upload-btn {
  width: 90px;
  height: 36px;
  font-size: 6px;
  position: relative;
  left: -117px;
  top: -1px;
}
.charging-text {
  padding: 10px 0;
  text-align: left;
}
.success-login {
  color: #7bd146;
}
