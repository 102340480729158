.content-qrview {
  background-color: #1d1d1d;
  height: 100vh;
  overflow: hidden;
}
.content-qrview .card {
  background-color: inherit !important;
  color: #fff;
}
.content-qrview .card h1 {
  font-size: 47px;
}

.content-qrview .card h5 {
  background: #fff;
  padding: 10px;
  color: #333;
  border-radius: 3px;
  text-transform: uppercase;
}
.content-qrview .card h5 span {
  font-size: 15px;
}
.content-qrview .card p {
  text-align: left;
}
.content-qrview p.p-leading {
  text-align: center;
}

a.qrLink {
  padding: 7px;
  display: block;
  text-decoration: none;
  color: #ededed;
  background: #72be44;
  text-transform: uppercase;
  font-size: 14px;
}
a.qrLink:hover {
  color: #ededed;
}
a.qrLink:active {
  color: #ededed;
}
